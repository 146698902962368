<template>
  <div class="d-flex flex-wrap justify-content-between mb-2">
    <div class="d-flex flex-wrap">
      <div class="">
        <b-link
          v-for="tag in blogDetail.blog.tags"
          :key="tag"
        >
          <b-badge
            pill
            class="mr-75"
            :variant="tagsColor(tag)"
          >
            {{ tag }}
          </b-badge>
        </b-link>
      </div>
      <div class="">
        <h4>{{ blogDetail.blog.title }}</h4>
      </div>
    </div>
    <div class="text-right">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="$router.push({ name: 'manuel-utilisation' })"
      >
        <!-- <feather-icon
                icon="icon-arrow-left"
                class="mr-50"
              /> -->
        <i class="feather icon-arrow-left mr-50" />
        <span class="align-middle">Retour au manuel d'utilisation</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BImg, BLink, BBadge, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
  components: {
    BImg,
    BLink,
    BBadge,
    BButton
  },
  directives: { Ripple,},
  props: {
    blogDetail: {
      type: Object,
      default: () => ({
        blog: {
          tags: [],
          title: '',
        },
      }),
    },
  },
  computed: {
    logoImgProps() {
      return {
        blank: true,
        width: this.width,
        height: this.height,
      }
    },
  },
  methods: {
    navigateToHome() {
      window.location = '/'
    },
     tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Promoteur Etablissement') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
}
</script>
<style lang="scss" scoped></style>
